import React from "react"
import { Link } from "gatsby"
const ResourceCard = ({ title, heading, src, link, layout, synopsis, actionText, theme}) => {
  return (
      <Link
        to={`/resources/${link}`}
        className={`shadow-[0_2px_12px_rgba(0,0,0,0.09)]  rounded-sm flex-1 flex transition-transform duration-500 ease-in-out transform hover:scale-[1.025] ${layout==='Vertical' ? 'flex-col md:flex-row md:max-h-full' : 'flex-col'}
        ${theme === 'dark' ? 'bg-PrimaryHoverBlack' : ''}`}
      >
        <div className={`block ${layout==='Vertical' ? 'w-full md:w-1/2 ' : 'w-full h-[200px] '}`}>
          <img
            className="object-cover object-center w-full h-full overflow-hidden"
            src={src}
            alt="Rectangle"

          />
        </div>
        <div className={`px-5 ${layout === 'Vertical' ? 'md:w-3/4 mb-[30px] md:mb-0' : ''}`}>
          <p
            className={`w-full text-sm font-normal tracking-widest uppercase Figtree-Regular text-ProjectBlue mb-[20px] ${layout === 'Vertical' ? "md:mt-[40px]": "mt-[30px]"} `}
            data-sal="slide-up"
            data-sal-duration="1200"
            data-sal-delay="0"
            data-sal-easing="ease-animationSection"
          >
            {title}
          </p>
          <h6
            className = {`w-full Saira-Bold text-[21px] leading-lineheight140 mb-[20px] ${layout === 'Vertical' ? "" : "lg:max-h-fit"}`}
            data-sal="slide-up"
            data-sal-duration="1200"
            data-sal-delay="0"
            data-sal-easing="ease-animationSection"
          >
            {heading}
          </h6>
          <p
            className="text-[17px] font-workSans font-normal leading-normal mb-[20px]"
            data-sal="slide-up"
            data-sal-duration="1200"
            data-sal-delay="0"
            data-sal-easing="ease-animationSection"
          >{synopsis}
          </p>
          <p
            className={`text-[19px] text-TextPrimaryBlue font-workSans font-normal leading-lineheight140 next hover:underline transition duration-300 ease-in-out ${layout === 'Vertical' ? "mb-[30px] md:mb-[25px]" : "mb-[30px]"}`}
            data-sal="slide-up"
            data-sal-duration="1200"
            data-sal-delay="0"
            data-sal-easing="ease-animationSection"
          >
            {actionText ? actionText : 'Read More'}
            <span className="ml-[21px] arrow">&#10140;</span>
          </p>
        </div>
      </Link>
  )
}

export default ResourceCard