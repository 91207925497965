import React from "react"
import Button from "../button"

const CallToAction = ({ CalltoActionData }) => {
  const theme = CalltoActionData?.theme?.[0]
  return (
    <div className= {`${theme === 'Grey' ? 'bg-PrimaryGrey' : theme === 'Dark' ? 'bg-Black' : 'bg-white'}`}>
      <div className="flex flex-col items-center justify-center w-full max-w-screen-xl px-3 py-20 mx-auto ">
          <h1
            className={`${theme === 'Dark' ? 'text-ProjectBlack' : 'text-black'} tracking-wide text-center text-[32px] md:text-[56px] Saira-Bold font-normal leading-[120%] my-3 max-w-[720px] translate-y-[10px]`}
            data-sal="slide-up"
            data-sal-duration="1200"
            data-sal-delay="0"
            data-sal-easing="ease-animationSection"
          >
          {CalltoActionData.title}
          </h1>
        <p
          className={`font-workSans font-normal text-[19px] text-center ${theme === 'Dark' ? 'text-ProjectBlack' : 'text-black'} max-w-[556px] leading-normal tracking-wide translate-y-[10px]`}
          data-sal="slide-up"
          data-sal-duration="1200"
          data-sal-delay="150"
          data-sal-easing="ease-animationSection"
        >
          {CalltoActionData?.desc}
        </p>
        <div
          className="flex items-center justify-center mt-10 translate-y-[10px]"
          data-sal="slide-up"
          data-sal-duration="1200"
          data-sal-delay="300"
          data-sal-easing="ease-animationSection"
        >
          {CalltoActionData.buttons?.map((button, ind) => (
            <div className="mr-5" key={ind}>
              <Button
                link={button?.url}
                title={button.title}
                variant={button.elementType === "primaryButton" ? "primary" : "secondary"}
                theme={button.theme[0].toLowerCase()}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CallToAction
