import React from "react"
import Button from "../../components/button"
// import { BsArrowLeft } from "react-icons/bs"
const ResourceFeatured = ({ featuredPosts, backlink }) => {

	return (
		<div className="w-screen min-h-[512px] relative blog-gradient">
			<div className="absolute top-0 h-full -z-20">
				<img
					src={featuredPosts.resourceGraphic.file.url}
					alt=""
					className="object-cover w-screen h-full"
				/>
			</div>
			<div className="px-5 md:px-10 xl1:px-40  2xl:px-[70px]  md:-mt-[118px]">
				<div className="flex items-center md:items-start justify-start max-w-screen-xl mx-auto min-h-[500px] md:min-h-[630px]">
					<div className="w-full items-center md:w-3/5 p-0 md:mt-[196.5px]">
						<p className={`text-base Figtree-Bold text-blue uppercase font-bold tracking-widest `}>{featuredPosts.resourceTag}</p>
						<h1 className="pt-[10px] text-[28px] md:text-[44px] font-normal text-ProjectBlack Saira-Bold leading-lineheight120">{featuredPosts.title}</h1>
						<p className="pt-[10px] font-workSans font-normal md:text-[19px] text-ProjectBlack leading-6">{featuredPosts.synopsis}</p>
						<div className="mt-[30px] flex items-center  md:mb-[58.5px]">
							<Button
								link={`/resources/${featuredPosts.title.replace(/\s+/g, '-').toLowerCase()}`}
								title="Download"
								variant="primary"
								theme="blue"
							/>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default ResourceFeatured