import React, { useEffect, useState, useMemo } from "react"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"
import ResourceCard from "../../components/resourceCard"

const ResourceMostRecent = ({ data, renderPagination, limit, tagName }) => {

	const [currentPage, setCurrentPage] = useState(1)
	const [totalPage, setTotalPage] = useState(0)
	useEffect(() => {
		let pages = Math.ceil(data.length / limit)
		setTotalPage(pages - 1)
	}, [data, limit]);
	const currentTableData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * limit
		const lastPageIndex = firstPageIndex + limit
		return data.slice(firstPageIndex, lastPageIndex)
	}, [currentPage, data, limit])

	const pagination = () => {
		let pages = totalPage + 1
		const pageList = []
		while (pages > 0) {
			pageList.push(pages)
			pages--
		}
		return (
			(pageList.length === 1) ? null :
				pageList.reverse().map(item => (
					<li
						className={`cursor-pointer  px-4 py-2  text-[21px]  text-blue ${currentPage === item ? " font-bold " : " font-semibold"
							}`}
						onClick={() => setCurrentPage(item)}
						role="presentation"
					>
						{item}
					</li>
				))
		)
	}
	return (
		<>
			<div className="w-screen bg-white px-5 md:px-10 xl1:px-40">
				<div className="max-w-screen-xl mx-auto">
					<div className="flex flex-wrap items-center justify-between py-10 md:py-20">
						<p className="Saira-Bold capitalize font-normal md:text-[44px] text-black leading-lineheight120">
							{tagName}
						</p>
					</div>
					<div class={`grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-[30px] ${renderPagination === true ? " pb-10 md:pb-[108px]": "  md:pb-[93px]"}`}>
						{currentTableData.map((article, index) => {
							return (
								<ResourceCard
									title={article?.resourceTag}
									heading={article?.title}
									src={article?.resourceGraphic?.file?.url}
									link={article?.title.replace(/\s+/g, '-').toLowerCase()}
									synopsis={article.synopsis}
									actionText={article?.actionText}
								/>
							)
						})}
					</div>
				</div>
			</div>
			{renderPagination && pagination() && (
				<div className='pb-10 md:pb-20 bg-white'>
					<ul className='flex pl-0 rounded list-none flex-wrap justify-center'>
						<li className="pt-[14px] text-blue cursor-pointer  px-4 py-2  text-[21px]">
							<BsArrowLeft
								className={renderPagination && currentPage > 1 ? " visible" : " invisible"}
								onClick={() => {
									setCurrentPage(currentPage - 1)
								}}
							/>
						</li>
						{renderPagination && pagination()}
						<li className="pt-[14px] text-blue cursor-pointer  px-4 py-2  text-[21px]">
							<BsArrowRight
								className={renderPagination && currentPage <= totalPage ? " visible" : " invisible"}
								onClick={() => {
									setCurrentPage(currentPage + 1)
								}}
							/>

						</li>
					</ul>
				</div>
			)}

		</>
	)
}

export default ResourceMostRecent