import React from "react"
import { Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"

const Notice = ({ NoticeData }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <span className="ml-1 md:ml-4 text-xs md:text-sm text-center text-black Saira-Bold tracking-normal leading-lineheight130 md:leading-normal font-medium md:font-normal">
            {children}
          </span>
        )
      },
    },
  }
  return (
    <div
      className={`w-screen h-[60px] md:py-5 px-5 md:px-10 xl1:px-40 cursor-pointer relative z-[1]
      ${NoticeData?.theme?.[0].toLowerCase() === "light"
          ? "bg-ProjectBlack text-black hover:bg-white"
          : "bg-black text-ProjectBlack"
        }
        `}
    >
      <div className="flex items-center justify-center w-full h-full max-w-screen-xl mx-auto">
        <Link
          to={`${NoticeData?.actionUrl}`}
          className="flex items-center"
        >
          <p className="text-[12px] md:text-[14px] text-center text-ProjectBlue Figtree-SemiBold font-semibold tracking-widest leading-lineheight100">
            {NoticeData?.tagline}
          {renderRichText(NoticeData?.noticeTitle, options)}
          </p>
        </Link>
      </div>
    </div>
  )
}

export default Notice
