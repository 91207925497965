import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Notice from "../components/notice"
import Navbar from "../components/navbar"
import CallToAction from "../components/callToAction"
import Footer from "../components/footer"
import ResourceFeatured from "../components/resourceFeatured"
import ResourceHeader from "../components/resourceHeader"
import ResourceMostRecent from "../components/resourceMostRecent"
import Seo from "../components/seo"

const Resources = ({ data }) => {
  const [tags, setTags] = useState([])
  const NoticeData = data?.notice?.edges?.[0]?.node
  const NavbarData = data?.navbar?.edges?.[1]?.node
  const CalltoActionData = data?.callToAction?.edges?.[0]?.node
  const footerData = data?.footerColumn?.edges?.[1]?.node

  const featured = data.featured.edges[0]?.node

  const title = featured?.title
  const metaDescription = featured?.synopsis?.eventDatetime
  const imageUrl = featured?.resourceGraphic?.file?.url
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const resourceData = data.allContentfulResource.edges
  const recentRES = []
  const popularRES = []
  resourceData.forEach(({ node: res }) => {
    if (res.popular === true)
      popularRES.push(res)
    else
      recentRES.push(res)
  })
  useEffect(() => {
    const allTags = []
    resourceData.forEach(({ node: res }) => {
      !allTags.includes(res.resourceTag) && allTags.push(res.resourceTag)
    })
    setTags(allTags.slice(0, 6))
  }, [resourceData])
  return (
    <>
      <Seo
        title={title}
        metaDescription={metaDescription}
        imageUrl={imageUrl}
        url={url}
      />
      {NoticeData && <Notice NoticeData={NoticeData} />}
      {NavbarData && <Navbar NavbarData={NavbarData} />}
      {featured && <ResourceFeatured featuredPosts={featured} backlink={false} />}
      <ResourceHeader
        tags={tags}
      />
      <ResourceMostRecent
        data={recentRES}
        renderPagination={true}
        limit={6}
        tagName="Browse all Resources"
      />
      {CalltoActionData && <CallToAction CalltoActionData={CalltoActionData} />}
      <Footer variant="dark" footerData={footerData?.footerColumns} atlasOneLogo={footerData?.atlasOneLogo} />
    </>
  )
}
export default Resources

export const query = graphql`
  query resourcesQuery {
  allContentfulResource( sort : { fields: [updatedAt], order:DESC }) {
    edges {
      node {
        title
        eventDatetime
        synopsis
        description {
          raw
        }
        featured
        resourceGraphic {
            title
            file {
                url
            }
        }
        resourceTag
        hubspotFormId
        actionText
      }
    }
  }
  featured:  allContentfulResource(limit: 1, sort : { fields: [updatedAt], order:DESC },filter: {  featured : { eq: true } }) {
    edges {
      node {
        title
        eventDatetime
        synopsis
        description {
          raw
        }
        featured
        resourceGraphic {
            title
            file {
                url
            }
        }
        resourceTag
        hubspotFormId
        updatedAt
      }
    }
  }

  notice: allContentfulNotice {
    edges {
      node {
        elementType
        theme
        noticeTitle {
          raw
        }
        tagline
        actionUrl
        noticeActionText
      }
    }
  }

  navbar: allContentfulHeader {
    edges {
      node {
        atlasOneLogo {
          image {
            file {
              url
            }
            title
          }
        }
        headerButtons {
          ... on ContentfulPrimaryButton {
            elementType
            id
            theme
            title
            url
          }
          ... on ContentfulSecondaryButton {
            elementType
            id
            theme
            title
            url
          }
        }
        headerMenuItems {
          id
          title
          headerMenuDropdown {
            items {
              description
              icon {
                file {
                  url
                }
                title
              }
              title
              url
              page {
                slug
                title
              }
            }
          }
        }
      }
    }
  }

  callToAction: allContentfulCallToAction {
    edges {
      node {
        elementType
        theme
        title
        desc:description
        buttons {
          ... on ContentfulSecondaryButton {
            elementType
            theme
            title
            url
          }
          ... on ContentfulPrimaryButton {
            elementType
            theme
            title
            url
          }
        }
      }
    }
  }

  footerColumn: allContentfulFooter {
    edges {
      node {
        elementType
        footerColumns {
          columnTitle
          links {
            title
            description
            icon {
              file {
                url
              }
            }
            url
            page {
              slug
              title
            }
          }
        }
        atlasOneLogo {
          image {
            file {
              url
            }
            title
          }
        }
      }
    }
  }
  allContentfulPage {
    edges {
      node {
        slug
        metaTitle
        metaDescription
        ogImage{
            file{
                url
            }
        }
        sections {
          ... on ContentfulNotice {
            elementType
            theme
            noticeTitle {
              raw
            }
            tagline
            actionUrl
            noticeActionText
          }
          ... on ContentfulHeader {
            headerMenuItems {
              title
              headerMenuDropdown {
                items {
                  description
                  icon {
                    file {
                      url
                    }
                    title
                  }
                  title
                  url
                  page {
                    slug
                    title
                  }
                }
              }
            }
            headerButtons {
              ... on ContentfulPrimaryButton {
                elementType
                id
                theme
                title
                url 
              }
              ... on ContentfulSecondaryButton {
                elementType
                id
                theme
                title
                url 
              }
            }
            atlasOneLogo {
              image {
                file {
                  url
                }
                title
              }
            }
          }
          ... on ContentfulCallToAction {
            elementType
            theme
            title
            buttons {
              ... on ContentfulSecondaryButton {
                elementType
                theme
                title
                url
              }
              ... on ContentfulPrimaryButton {
                elementType
                theme
                title
                url
              }
            }
          }
          ... on ContentfulFooter {
            elementType
            ... on Node {
              ... on ContentfulFooterColumn {
                columnTitle
                links {
                    title
                    description
                    icon {
                        file {
                            url
                        }
                    }
                    url
                    page {
                      slug
                      title
                    }
                }
              }
            }
            atlasOneLogo {
              image {
                file {
                  url
                }
                title
              }
            }
          }
        }
      }
    }
  }
}
`

